import axios from 'axios';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { PLAYER_URL } from '../src/Config/globals';
import useUnity from '../src/Hooks/useUnity';

const App = dynamic(() => import('../src/AppOld'), {
  ssr: false,
});

export default function Page(props) {
  useEffect(() => {
    if (typeof window !== 'undefined' && props?.redirect) {
      window.location.href = props?.redirect?.destination;
    }
  }, [props?.redirect]);

  useUnity();

  return (
    <App {...props} />
  );
}

Page.getInitialProps = async ({ query, req }) => {
  const { referer } = req?.headers || {};
  const { slug } = query;
  const { url = '/' } = req || {};
  if (url.includes('/Creation')) {
    const projectId = slug?.[1] !== '[object Object]' ? slug[1] : new URL(referer).pathname.split('/').pop();
    const embedParam = new URLSearchParams({
      oembed: true,
      watermark: false,
      hideCloseBtn: true,
      transparent: true,
      noFullScreen: true,
    });
    const embedUrl = `${PLAYER_URL}/Embed/${projectId}?${embedParam.toString()}`;
    const oembedUrl = `https://studio.assemblrworld.com/api/oembed?url=${encodeURIComponent(embedUrl)}`;

    const { data } = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/v2/creations/${projectId}`);

    if (data?.detail?.toLowerCase().includes('not found')) {
      return { redirect: { destination: '/404', permanent: false } };
    }

    return {
      seo: {
        meta: [
          {
            name: 'iframely:attach',
            content: embedUrl,
          },
          {
            name: 'iframely:medium',
            content: 'rich',
          },
        ],
        link: [
          {
            rel: 'iframely app 3d',
            href: embedUrl,
          },
          {
            rel: 'alternate',
            type: 'application/json+oembed',
            href: oembedUrl,
          },
        ],
        image: data?.PublishedThumbnail || data?.Thumbnail,
        description: data?.Description,
        title: `${data?.Name} - on Assemblr Edu`,
      },
      creation: data,
    };
  }
  return {
    seo: null,
  };
};
